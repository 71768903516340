<template>
  <div class="content">
    <div class="header">
      <img src="../assets/img/left.png" @click="goback()"/> 
      <p>{{title}}</p>
    </div>

    <div class="mt50"></div>

    <div class="basic">
      <div class="basic_top flex">
        <p>订单列表</p>
        <span @click="orderInfo(type)">添加</span>
      </div>
      <div class="basic_list" v-if="orderList.length > 0">
        <div class="item" v-for="(item, index) in orderList">
          <div class="my_info">
            <div class="my_item flex"><p>订单编号：{{item.order_no}}</p></div> 
            <div class="my_item flex"><p>订单状态：{{item.status_text}}</p></div> 
            <div class="my_item flex" v-if="type == 1"><p>到期时间：{{item.endtime}}</p></div> 
            <div class="my_item flex"><p>债务人姓名：{{item.username}}</p></div> 
            <div class="my_item flex" v-if="type == 4"><p>借款平台：{{item.platform}}</p></div> 
          </div>

          <div class="contacts" v-if="type < 4">
            <div class="contact_item flex" v-for="(card, idx) in item.cardList">
              <p>逾期卡片{{idx+1}}</p><p>{{card.bank_name}} {{card.card_number.substr(-4)}}</p><p>{{card.status_text}}</p>  
            </div>
          </div>  
        </div>

      </div>

      <div class="no_date" v-else>暂无数据~</div>

    </div>    
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'OrderView',
  components: {
    Footer
  },
  data() {
      return {
        title: '',
        type: 1,
        titleArr: ['债务托管', '代协商债务', '个性化分期', '网贷停催'],
        orderList: []
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {
    this.type = this.$route.query.type;
    this.title = this.titleArr[this.type - 1];
    this.getOrderList();
  },

  // 方法
  methods: {
    //获取首页数据
    getOrderList(){
      this.axios.get(
        '/api/orders/index?type='+this.type, {headers: {
          'token': localStorage.getItem('token')
        }
      }).then((res)=>{
        this.orderList = res.data.data
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    goback(){
      this.$router.push({path:'/index'})
    },

    orderInfo(type){
      this.$router.push({path:'/orderInfo', query: {type: type, order_id: 1}})
    }
  },
        
}
</script>

<style scoped>
  
  .basic .basic_list .item .my_info .my_item p{
    width: 100%;
    font-size: 15px;
    line-height: 25px;
    text-align: left;
  }
  .basic .basic_list .item .contacts .contact_item p{
    width: 30%;
    height: 30px;
    line-height: 30px;
    background: #eee;
  }
  .basic .basic_list .item .contacts .contact_item p:first-child{
    text-align: left;
    background: none;
  }
  .basic .basic_list .item .contacts .contact_item p:nth-child(2){
    width: 40%;
    margin-right: 10px;
  }
</style>
