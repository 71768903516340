<template>
  <div class="content">
    <div class="header">
      <img src="../assets/img/left.png" @click="goback()"/> 
      <p>我的卡片</p>
    </div>
    
    <div class="mt50"></div>
    
    <div class="basic">
      <div class="basic_top flex">
        <p>卡片列表</p>
        <span @click="cardInfo()">添加</span>
      </div>
      <div class="basic_list">

        <div class="item" v-for="(item, index) in cardList">
          <div class="my_info">
            <div class="my_item flex"><p>持卡人</p><input v-model="item.user_name" readonly/></div> 
            <div class="my_item flex"><p>开户行</p><input v-model="item.bank_name" readonly/></div> 
            <div class="my_item flex"><p>卡号</p><input v-model="item.card_number" readonly/></div> 
            <div class="my_item flex"><p>欠款金额</p><input v-model="item.amount" readonly/></div> 
            <div class="my_item flex"><p>激活城市</p><input v-model="item.activation" readonly/></div> 
            <div class="my_item flex"><p>预留手机号</p><input v-model="item.mobile" readonly/></div> 
            <div class="my_item flex"><p>查询密码</p><input type="text" v-model="item.query_pass" readonly/></div> 
            <div class="my_item flex"><p>交易密码</p><input type="text" v-model="item.trade_pass" readonly/></div> 
            <div class="my_item flex"><p>还款日</p><input v-model="item.repay_date" readonly/></div> 
            <div class="my_item flex"><p>逾期时间</p><input v-model="item.exceed_date" readonly/></div> 
            <div class="my_item flex">
              <p>紧急联系人</p>
              <div class="contact">
                <p v-for="(contact, idx) in item.contacts" class="flex">{{contact.name}} {{contact.relation}} {{contact.mobile }}</p>
              </div>
            </div> 
          </div>
            
          <div class="operates">
            <el-row>
              <!-- <el-button type="primary" @click="deleteCard(item.id)">删除</el-button> -->
              <el-button type="warning" @click="cardInfo(item.id)">修改</el-button>
            </el-row>
          </div>
        </div>

      </div>

      <div class="no_date" v-if="cardList.length < 1">暂无数据~</div>

    </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'CardsView',
  components: {
    Footer
  },
  data() {
      return {
        cardList: [],
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {
    this.getMyCardList();
  },

  // 方法
  methods: {
    //获取卡片列表
    getMyCardList(){
      this.axios.get(
        '/api/mycard/index', {headers: {
          'token': localStorage.getItem("token")
        }
      }).then((res)=>{
        this.cardList = res.data.data
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    goback(){
      this.$router.push({path:'/index'})
    },

    //删除卡片
    deleteCard(cardId){
      this.axios.post(
        'api/mycard/deleteCard', {id: cardId}, {headers: {
          'token': localStorage.getItem("token")
        }
      }).then((res)=>{
        this.$dialog(res.data.msg);
        if(res.data.code == 1){
          this.getMyCardList();
        }else{
          return false;
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    //卡片详情
    cardInfo(cardId){
      this.$router.push({path:'/cardInfo', query: {id: cardId}})
    }
  },
        
}
</script>

<style scoped>
 .basic .basic_list{padding-bottom: 0;}
 .basic .basic_list .item .my_info .my_item .contact{
  width: 70%;
 }
 .basic .basic_list .item .my_info .my_item .contact p{
  width: 100%;
  height: 35px;
  border: none;
  background: #eee;
  border-radius: 3px;
  padding-left: 10px;
  margin-bottom: 10px;
  color: #000;
 }
</style>
