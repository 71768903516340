<template>
  <div class="content">
    <div class="header">
      <img src="../assets/img/left.png" @click="goback()"/> 
      <p>基本信息</p>
    </div>

    <div class="mt50"></div>
    
    <div class="basic">
      <div class="basic_top flex">
        <p>信息列表</p>
        <span @click="basicInfo()">添加</span>
      </div>
      <div class="basic_list">

        <div class="item" v-for="(item, index) in basicList">
          <div class="my_info">
            <div class="my_item flex"><p>姓名</p><input :value="item.name" readonly/></div> 
            <div class="my_item flex"><p>性别</p><input :value="item.gender_text" readonly/></div> 
            <div class="my_item flex"><p>身份证号</p><input :value="item.idcard" readonly/></div> 
            <div class="my_item flex"><p>年龄</p><input :value="item.age" readonly/></div> 
            <div class="my_item flex"><p>学历</p><input :value="item.education_text" readonly/></div> 
            <div class="my_item flex"><p>属相</p><input :value="item.animal" readonly/></div> 
            <div class="my_item flex"><p>婚姻状况</p><input :value="item.marry_text" readonly/></div> 
            <div class="my_item flex"><p>邮箱号</p><input :value="item.email" readonly/></div> 
            <div class="my_item flex"><p>户籍地址</p><input :value="item.address" readonly/></div> 
            <div class="my_item flex"><p>银行预留地址</p><input :value="item.bank_addr" readonly/></div> 
            <div class="my_item flex"><p>预留单位名称</p><input :value="item.company" readonly/></div> 
            <div class="my_item flex"><p>预留单位地址</p><input :value="item.company_addr" readonly/></div> 
            <div class="my_item flex"><p>银行预留手机号</p><input :value="item.mobile" readonly/></div> 
            <div class="my_item flex"><p>新办联通号</p><input :value="item.uni_mobile" readonly/></div> 
          </div>

          <!-- <div class="contacts">
            <div class="contact_item contact_head flex">
              <p>紧急联系人</p><p>关系</p><p>手机号</p>  
            </div>

            <div class="contact_item flex" v-for="(contact, idx) in item.contacts" v-if="contact.name && contact.relation && contact.mobile">
              <p>{{contact.name}}</p><p>{{contact.relation}}</p><p>{{contact.mobile}}</p>  
            </div>
          </div>   -->

          <div class="operates">
            <el-row>
              <!-- <el-button type="primary" @click="delBasicInfo(item.id)">删除</el-button> -->
              <el-button type="warning" @click="basicInfo(item.id)">修改</el-button>
            </el-row>
          </div>
        </div>

      </div>

      <div class="no_date" v-if="basicList.length < 1">暂无数据~</div>
    </div>

    
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'BasicView',
  components: {
    Footer
  },
  data() {
      return {
        basicList: []
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {
    this.getBasicInfoList();
  },

  // 方法
  methods: {
    //获取基本信息列表
    getBasicInfoList(){
      this.axios.get(
        '/api/userinfo/getBasicInfoList', {headers: {
          'token': localStorage.getItem("token")
        }
      }).then((res)=>{
        this.basicList = res.data.data
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    //跳转详情页
    basicInfo(id){
      this.$router.push({path:'/basicInfo', query: {id: id}})
    },

    //删除
    delBasicInfo(id){
      this.axios.post(
        'api/userinfo/delBasicInfo', {id: id}, {headers: {
          'token': localStorage.getItem("token")
        }
      }).then((res)=>{
        this.$dialog(res.data.msg);
        if(res.data.code == 1){
          this.getBasicInfoList();
        }else{
          return false;
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    goback(){
      this.$router.push({path:'/index'})
    },

  },
        
}
</script>

<style scoped>
  .basic .basic_list{padding-bottom: 0;}
  
</style>
