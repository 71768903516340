var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_vm._m(0),_c('div',{staticClass:"mt50"}),_c('div',{staticClass:"banner"},[[_c('el-carousel',{attrs:{"indicator-position":"outside","interval":3500}},_vm._l((_vm.banner),function(item,index){return _c('el-carousel-item',{key:index},[_c('img',{attrs:{"src":item.url}})])}),1)]],2),_vm._m(1),_c('div',{staticClass:"nav_box"},[_c('ul',[_c('li',{staticClass:"flex",on:{"click":function($event){return _vm.basic()}}},[_vm._m(2),_vm._m(3)]),_c('li',{staticClass:"flex",on:{"click":function($event){return _vm.cards()}}},[_vm._m(4),_vm._m(5)]),_c('li',{staticClass:"flex",on:{"click":function($event){return _vm.order(1)}}},[_vm._m(6),_vm._m(7)]),_c('li',{staticClass:"flex",on:{"click":function($event){return _vm.order(2)}}},[_vm._m(8),_vm._m(9)]),_c('li',{staticClass:"flex",on:{"click":function($event){return _vm.order(3)}}},[_vm._m(10),_vm._m(11)]),_c('li',{staticClass:"flex",on:{"click":function($event){return _vm.order(4)}}},[_vm._m(12),_vm._m(13)])])]),_c('Footer',{attrs:{"activeIndex":"0"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('p',[_vm._v("首页")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intro flex"},[_c('div',{staticClass:"item flex"},[_c('img',{attrs:{"src":require("../assets/img/icon_lawer.png")}}),_c('span',[_vm._v("专业律师")])]),_c('div',{staticClass:"item flex"},[_c('img',{attrs:{"src":require("../assets/img/icon_role.png")}}),_c('span',[_vm._v("合规合法")])]),_c('div',{staticClass:"item flex"},[_c('img',{attrs:{"src":require("../assets/img/icon_service.png")}}),_c('span',[_vm._v("全程服务")])]),_c('div',{staticClass:"item flex"},[_c('img',{attrs:{"src":require("../assets/img/icon_process.png")}}),_c('span',[_vm._v("流程透明")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav_title"},[_c('p',[_vm._v("基本信息")]),_c('span',[_vm._v("录入个人基本信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon flex"},[_c('img',{attrs:{"src":require("../assets/img/icon_nav1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav_title"},[_c('p',[_vm._v("我的卡片")]),_c('span',[_vm._v("录入卡片信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon flex"},[_c('img',{attrs:{"src":require("../assets/img/icon_nav2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav_title"},[_c('p',[_vm._v("债务托管")]),_c('span',[_vm._v("债务托管服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon flex"},[_c('img',{attrs:{"src":require("../assets/img/icon_nav3.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav_title"},[_c('p',[_vm._v("代协商债务")]),_c('span',[_vm._v("债务协商服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon flex"},[_c('img',{attrs:{"src":require("../assets/img/icon_nav4.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav_title"},[_c('p',[_vm._v("个性化分期")]),_c('span',[_vm._v("债务分期服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon flex"},[_c('img',{attrs:{"src":require("../assets/img/icon_nav5.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav_title"},[_c('p',[_vm._v("网贷停催")]),_c('span',[_vm._v("提供网贷停催服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon flex"},[_c('img',{attrs:{"src":require("../assets/img/icon_nav6.png")}})])
}]

export { render, staticRenderFns }