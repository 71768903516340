<template>
  <div class="content">
    <div class="header">
      <img src="../assets/img/left.png" @click="goback()"/> 
      <p>客服</p>
    </div>

    <div class="msg_main">
      <div class="msg_box" id="msg_box">
        <div class="msg_list" ref="msgList">
          <div class="item" v-for="(item, index) in msgList" :class="item.type == 2 ? 'left' : 'right'">
            <img src="../assets/img/avatar.png" />
            <div class="msg">
              <span>{{item.sender}} {{item.createtime}}</span>
              <div class="msg_cnt">
                <p v-if="!item.msg_type || item.msg_type == 'text'">{{item.content}}</p>
                <img v-else-if="item.msg_type == 'image'" :src="item.content" @click="prevImg(item.content)"/>
                <video v-else-if="item.msg_type == 'video'"  controls>
                  <source :src="item.content" />
                </video>
                <audio v-else-if="item.msg_type == 'audio'" controls>
                  <source :src="item.content" />
                </audio>
                <object v-else :src="item.content" @click="openFile(item.content)">
                  <img src="../assets/img/file.svg" /> 附件
                </object>
              </div>
            </div>
          </div>
          <div v-if="msgList.length < 1">暂无消息~</div>
        </div>
      </div>      

      <div class="send_box">
        <form>
          <textarea v-model="content"></textarea>
          <div class="upload_file">
            <div class="item">
              <el-upload
                  class="avatar-uploader"
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="handleUploadSuccess"
                  :before-upload="beforeUpload">
                  <!-- <img v-if="fileUrl" src="../assets/img/audio.png" class="avatar"> -->
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </div>
          </div>
          <input type="submit" value="发送" @click="sendMsg()"/>
        </form>
      </div>
    </div>

    <div class="img_prev" v-if="prev_url" @click="closePrev">
      <img :src="prev_url" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatView',
  data() {
      return {
        uploadUrl: this.axios.defaults.baseURL + '/api/common/upload', //图片上传地址
        fileUrl: '',
        fileName: '',
        msg_type: 'text',
        prev_url: '',
        content: '',
        adminId: 0,
        msgList: [],
        timer: ''
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {
    const token = localStorage.getItem("token");
    if(!token){
      this.$dialog('请先登录');
      return false;
    }
    
    this.adminId = this.$route.query.adminId;
    if(!this.adminId){
      this.$dialog('客服id不能为空');return false;
    }
    this.getMsgDetail();    
    setTimeout(res => {
      this.refreshMsgList();
    }, 1000)

    // 滚动到底部
    var that = this;
    setTimeout(function(){
      that.scrollToBottom();
    }, 500)
    
  },

  // 方法
  methods: {
    openFile(resource){
      window.open(resource, '_blank');
    },

    //预览
    prevImg(url){
      this.prev_url = url
    },
    closePrev(){
      this.prev_url = ''
    },

    // 消息列表
    getMsgDetail(){
      this.axios.get(
        '/api/Message/getMsgDetail?adminId=' + this.adminId, {headers: {
          'token': localStorage.getItem('token')
        }
      }).then((res)=>{
        this.msgList = res.data.data;
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    //发送消息
    sendMsg(){
      if(!this.content){
        this.$dialog('消息内容不能为空'); return false;
      }
      this.axios.post(
        'api/Message/sendMsg', {
          receive_id: this.adminId,
          content: this.content,
          msg_type: this.msg_type
        }, {headers: {
          'token': localStorage.getItem("token")
        }
      }).then((res)=>{
        if(res.data.code == 1){
          this.content = '';
          this.getMsgDetail();
          setTimeout(() => {
            this.scrollToBottom();
          }, 300);
        }else{
          this.$dialog(res.data.msg);
          return false;
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    //刷新对话信息
    refreshMsgList(){
      var that = this;
      this.timer = setInterval(function(){
        that.getMsgDetail();
      }, 5000)
    },

    scrollToBottom() {
      // 在下一个 DOM 更新周期中执行回调函数
      const elementHeight = this.$refs.msgList.scrollHeight;
      this.$refs.msgList.scrollTop = elementHeight;
    },

    //附件
    handleUploadSuccess(res, file) {
      if(res.code == 1){
        this.fileUrl = res.data.fullurl;
        this.content = res.data.fullurl;
        this.showConfirm();
      }
      
    },
    beforeUpload(file) {
      console.log(file)
      this.fileName = file.name
      this.msg_type = file.type.split('/')[0];
      console.log(this.msg_type)

      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$dialog('附件大小不能超过20MB'); return false;
      }
      return isLt20M;
    },

    showConfirm(){
      const message = '确定发送文件'+this.fileName+'吗？';
      this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'primary'
      }).then(() => {
        this.sendMsg();
      }).catch(() => {
        console.log("cancel")          
      });
    },

    goback(){
      this.$router.push({path:'/customer'})
    },
  },

  beforeDestroy() {
    // 在销毁前执行一些逻辑:清楚定时器
    clearInterval(this.timer);
  },
        
}
</script>

<style scoped>
  .el-message-box{
    max-width: 90%;
  }
  .el-message-box .el-message-box__title{
    text-align: center;
  }

  .msg_main {
    width: 100%;
    height: 100vh; 
    overflow: hidden;
    padding: 60px 10px 10px;
  }
  .msg_box{
    height: 75%; 
    overflow: hidden; 
    scroll-behavior: smooth;
    background: #fff;
    border: 1px solid #ccc; 
    border-radius: 5px;
    padding: 10px;
  }
  .msg_box .msg_list{
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .msg_box .item{
    width:100%; 
    margin-bottom: 10px; 
    overflow: hidden;
  }
  .msg_box .item > img{
    width: 30px; 
    height: 30px; 
    border-radius: 50%; 
    position: relative; 
    top: 5px;
  }
  .msg_box .item .msg .msg_cnt {
    width: 200px;
    padding-top: 3px;
  }
  .msg_box .item .msg .msg_cnt > img{
    width: 100%;
  }
  .msg_box .item .msg video{
    width: 100%;
  }
  .msg_box .item .msg audio{
    width: 100%;
  }
  .msg_box .item .msg object > img{
    width: 40px;
  }
  .msg_box .left > img {
    float: left
  }
  .msg_box .left .msg {
    float: left; 
    text-align: left; 
    padding-left: 10px;
  }
  .msg_box .right > img{
    float: right
  }
  .msg_box .right .msg {
    float: right; 
    text-align: right; 
    padding-right: 10px;
  }
  .msg_box .right .msg p{
    text-align: right; 
  }
  .msg_box .msg {
    max-width: 70%;
    min-height: 40px;
  }
  .msg_box .msg span{
    color: #999; 
    font-size: 12px;
  }
  .msg_box .msg p{
    padding: 5px 10px; 
    background: #18bc9c; 
    border-radius: 5px; 
    line-height: 25px; color: #fff; 
    text-align: justify;
  }
  .msg_box .left .msg p{
    background: #eee; 
    color: #333;
  }
  /* //滚动条样式 */
  ::-webkit-scrollbar {
    width: 0px;
  }
  .send_box {
    width: 100%;
    height: 23%; 
    margin-top: 2%; 
    position: relative; 
    border: 1px solid #ccc; 
    border-radius: 5px; 
    overflow: hidden;
  }
  .send_box form{
    height: 100%;
  }
  .send_box textarea{
    width: 100%; 
    height: 100%; 
    outline: none; 
    line-height: 25px; 
    resize: none; 
    border: none; 
    padding: 5px 10px;
  }
  .send_box input {
    position: absolute; 
    right: 10px; 
    bottom: 10px; 
    background: #18bc9c; 
    color: #fff; 
    border: none; 
    width: 70px; 
    height: 30px; 
    border-radius: 3px;
  }
  .upload_file{
    position: absolute;
    right: 90px;
    bottom: 10px;
    width: 30px;
    height: 30px;
    background: #18bc9c;
    border-radius: 50%;
    line-height: 30px;
  }
  .upload_file >>> .el-upload .avatar-uploader-icon{
    color: #fff;
    font-size: 20px;
    line-height: 30px;
  }

  .img_prev{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(0,0,0,0.7);
  }
  .img_prev img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
</style>
