<template>
  <div class="footer flex">
      <div v-for="(item, index) in navItemArr" class="navbar" :class="index == activeIndex ? 'active' : ''">
        <router-link :to="item.url">
          <img :src="index == activeIndex ? item.selected : item.icon" />
          <p>{{item.title}}</p>
        </router-link>
      </div>
      <!-- <div class="navbar"><img src="../assets/img/icon_kefu.png" /><p>客服</p></div>
      <div class="navbar"><img src="../assets/img/icon_notice.png" /><p>公告</p></div>
      <div class="navbar"><img src="../assets/img/icon_member.png" /><p>我的</p></div> -->
    </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    activeIndex: 0
  },
  data() {
    return {
      navItemArr: [
        {
          title: '首页',
          icon: require('@/assets/img/icon_home.png'),
          selected: require('@/assets/img/icon_home_on.png'),
          url: '/index'
        },
        {
          title: '客服',
          icon: require('@/assets/img/icon_kefu.png'),
          selected: require('@/assets/img/icon_kefu_on.png'),
          url: '/customer'
        },
        {
          title: '公告',
          icon: require('@/assets/img/icon_notice.png'),
          selected: require('@/assets/img/icon_notice_on.png'),
          url: '/notice'
        },
        {
          title: '我的',
          icon: require('@/assets/img/icon_member.png'),
          selected: require('@/assets/img/icon_member_on.png'),
          url: '/member'
        }
      ],
    };
  },
  mounted(){
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer{
    width: 100%;
    padding:5px 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
}
.footer .navbar img{
    height: 30px;
}
.footer .active p{
    color: #0093dd;
}
</style>
