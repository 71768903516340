import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexView from '../views/IndexView.vue'
import CustomerView from '../views/CustomerView.vue'
import NoticeView from '../views/NoticeView.vue'
import MemberView from '../views/MemberView.vue'
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue'
import ForgetView from '../views/ForgetView.vue'
import BasicView from '../views/BasicView.vue'
import BasicInfoView from '../views/BasicInfoView.vue'
import CardsView from '../views/CardsView.vue'
import CardInfoView from '../views/CardInfoView.vue'
import OrderView from '../views/OrderView.vue'
import OrderInfoView from '../views/OrderInfoView.vue'
import ChangeView from '../views/ChangeView.vue'
import AuthView from '../views/AuthView.vue'
import InviteView from '../views/InviteView.vue'
import ChatView from '../views/ChatView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/', name: 'index', component: IndexView
  },
  {
    path: '/index', name: 'index', component: IndexView
  },
  {
    path: '/customer', name: 'customer', component: CustomerView
    //路由级代码拆分
    //这将为此路由生成一个单独的块（about.[hash].js）
    //当访问该路线时，其是惰性加载的。
    // component: function () {
    //   return import('../views/CustomerView.vue')
    // }
  },
  {
    path: '/notice', name: 'notice', component: NoticeView
  },
  {
    path: '/member', name: 'member', component: MemberView
  },
  {
    path: '/login', name: 'login', component: LoginView
  },
  {
    path: '/forget', name: 'forget', component: ForgetView
  },
  {
    path: '/register', name: 'register', component: RegisterView
  },
  {
    path: '/basic', name: 'basic', component: BasicView
  },
  {
    path: '/basicInfo', name: 'basicInfo', component: BasicInfoView
  },
  {
    path: '/cards', name: 'cards', component: CardsView
  },
  {
    path: '/cardInfo', name: 'cardInfo', component: CardInfoView
  },
  {
    path: '/order', name: 'order', component: OrderView
  },
  {
    path: '/orderInfo', name: 'orderInfo', component: OrderInfoView
  },
  {
    path: '/change', name: 'change', component: ChangeView
  },
  {
    path: '/auth', name: 'auth', component: AuthView
  },
  {
    path: '/invite', name: 'invite', component: InviteView
  },
  {
    path: '/chat', name: 'chat', component: ChatView
  },
  
]

const router = new VueRouter({
  mode: 'hash', // require service support
  routes
})

export default router
