<template>
  <div class="content">
    <div class="header">
      <img src="../assets/img/left.png" @click="goback()"/> 
      <p>找回密码</p>
    </div>

    <div class="mt50"></div>

    <div class="login">
      <div class="slogan">尚岸快乐</div>
      <div class="login_form">
        <input class="input_text" type="text" v-model="mobile" placeholder="请输入手机号"/>
        <input class="input_text" type="password" v-model="password" placeholder="请输入新密码"/>
        <input class="input_text" type="password" v-model="password2" placeholder="请再次输入新密码"/>
        <!-- <div class="code flex">
          <input class="input_text input_code" type="text" name="code" v-model="code" readonly/>
          <input class="input_submit btn_code" type="submit" value="验证码"/>
        </div> -->
        <p>忘记相关信息，请添加客服微信<span>{{wechat}}</span></p>
        <input class="input_submit" @click="forgetPass()" type="submit" value="提交"/>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'ForgetView',
  components: {
    Footer
  },
  data() {
      return {
        code: "",
        mobile: "",
        password: "",
        password2: "",
        wechat: "ydj432500"
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {
    this.getWechatNumber();
  },

  // 方法
  methods: {
    //登录
    forgetPass(){
      if(!this.mobile || !this.password || !this.password2){
        this.$dialog('账号密码不能为空');
        return false
      }
      
      this.axios.post( 'api/user/forgetPass', {
        mobile: this.mobile,
        password: this.password,
        password2: this.password2,
        code: this.code,
      }).then((res)=>{
        var that = this
        
        if(res.data.code == 1){
          this.$dialog('密码找回成功，请登录');
          setTimeout(function(){
            that.$router.push({path:'/login'})
          }, 1000)
        }else{
          this.$dialog(res.data.msg);
          return false;
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    getWechatNumber(){
      this.axios.get('api/user/getWechatNumber').then((res)=>{
        this.wechat = res.data.data
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    goback(){
      this.$router.go(-1);
    },

  }
        
}
</script>

<style scoped>
  .login_form p {
    line-height: 20px;
    text-align: left;
    color: #999;
    padding-bottom: 20px;
    font-size: 13px;
  }
  .login_form p span{
    color: #f00;
  }
</style>
