import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUi from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import './assets/css/common.css'
import "./assets/js/config"

//本地
// axios.defaults.baseURL = 'http://www.cards.com:81';
// 线上
axios.defaults.baseURL = 'http://card.zhushan.site';

Vue.config.productionTip = false
Vue.prototype.axios = axios;

// 创建全局方法
Vue.prototype.$dialog = function (msg) {
  this.$message.closeAll(); 
  this.$message({ message: msg, customClass: 'toast', duration: 2000 });
};

Vue.prototype.$checkError = function (res){
  if(res.response.data.code == 401){
    localStorage.removeItem('token');
    this.$dialog('登录已过期');
    setTimeout(() => {
      this.$router.push({path:'/login'})
    }, 2000);
  }else{
    this.$checkError(res);
  }
}

Vue.use(ElementUi)
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

