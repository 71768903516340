<template>
  <div class="content">
    <div class="header">
      <img src="../assets/img/left.png" @click="goback()"/> 
      <p>登录</p>
    </div>

    <div class="mt50"></div>
    
    <div class="login">
      <div class="slogan">尚岸快乐</div>
      <div class="login_form">
        <input class="input_text" type="number" v-model="mobile" placeholder="请输入手机号"/>
        <input class="input_text" type="password" v-model="password" placeholder="请输入密码"/>
        <input class="input_submit" type="submit" value="登录" @click="login()"/>
        <div class="others flex">
          <p @click="regiter">立即注册</p>
          <p @click="forget">忘记密码</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'LoginView',
  components: {
    Footer
  },
  data() {
      return {
        mobile: '',
        password: '',
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {
  },

  // 方法
  methods: {
    //登录
    login(){
      if(!this.mobile || !this.password){
        this.$dialog('账号密码不能为空');
        return false
      }
      
      this.axios.post(
        'api/user/login', {
          mobile: this.mobile,
          password: this.password,
        }
      ).then((res)=>{
        var that = this
        this.$dialog(res.data.msg);
        if(res.data.code == 1){
          localStorage.setItem('token', res.data.data.token)
          setTimeout(function(){
            that.$router.push({path:'/member'})
          }, 1000)
        }else{
          return
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    goback(){
      this.$router.go(-1);
    },

    regiter(){
      this.$router.push({path:'/register'})
    },

    forget(){
      this.$router.push({path:'/forget'})
    },
  }
        
}
</script>

<style scoped>
  
</style>
