<template>
  <div class="content">
    <div class="header"><p>公告</p></div>

    <div class="mt50"></div>
    
    <div class="notice_list">

      <div class="item" v-for="(item, index) in noticeList">
        <div class="head flex">
          <h5>{{item.title}}</h5><span>{{item.createDate}}</span>
        </div>
        <div class="detail">{{item.content}}</div>
      </div>

    </div>

    <div class="no_date" v-if="noticeList.length < 1">暂无数据~</div>

    <Footer activeIndex="2"/>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'NoticeView',
  components: {
    Footer
  },
  data() {
      return {
        noticeList: []
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {
    this.getNoticeList();
  },

  // 方法
  methods: {
    //公告列表
    getNoticeList(){
      this.axios.get(
        '/api/Notice/index', {headers: {
          'token': localStorage.getItem('token')
        }
      }).then((res)=>{
        console.log(res);
        this.noticeList = res.data.data;
      }).catch((res)=>{
        this.$checkError(res);
      })
    }
  }
        
}
</script>

<style scoped>
  .notice_list{
    padding: 0 10px;
  }
  .notice_list .item{
    padding: 15px;
    background: #fff;
    border-radius: 5px;
    margin-top: 10px;
  }
  .notice_list .item .head h5{
    font-size: 18px;
  }
  .notice_list .item .head span{
    color: #999;
  }
  .notice_list .item .detail{
    text-align: left;
    padding-top: 10px;
    line-height: 25px;
    color: #666;
    font-size: 15px;
  }
</style>
