<template>
  <div class="content">
    <div class="header"><p>我的</p></div>

    <div class="mt50"></div>
    
    <div class="member">
      <div class="user user_box flex">
        <div class="img">
          <div class="upload_file">
            <div class="item">
              <el-upload
                  class="avatar-uploader"
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="handleUploadSuccess"
                  :before-upload="beforeUpload">
                  <img v-if="avatar" :src="avatar" class="avatar">
                  <img v-if="!avatar" src="../assets/img/avatar.png" />
                </el-upload>
            </div>
          </div>
        </div>
        <div class="info" v-if="token">
          <h3>{{userInfo.nickname}}</h3><p>{{userInfo.mobile}}</p>
        </div>
        <div class="info" v-else  @click="login">
          <h3>请登录</h3>
        </div>
      </div>

      <div class="nav_list user_box">
        <ul>
          <li class="flex" @click="invite()"><p>邀请海报</p><img src="../assets/img/right.png" /></li>
          <li class="flex" @click="changePass()"><p>修改密码</p><img src="../assets/img/right.png" /></li>
          <li class="flex" @click="authBook()"><p>个人信息授权书</p><img src="../assets/img/right.png" /></li>
        </ul>
      </div>

      <div class="logout" @click="logout()" v-if="token">
        <el-button type="primary">退出登录</el-button>
      </div>

    </div>

    <Footer activeIndex="3"/>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'MemberView',
  components: {
    Footer
  },
  data() {
      return {
        token: '',
        userInfo: [],
        uploadUrl: this.axios.defaults.baseURL + '/api/common/upload', //图片上传地址
        avatar: '',
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {
    this.token = localStorage.getItem('token');
    if(this.token){
      this.getUserInfo();
    }
  },

  // 方法
  methods: {
    //获取用户信息
    getUserInfo(){
      this.axios.get(
        '/api/user/userInfo', {headers: {
          'token': this.token
        }
      }).then((res)=>{
        this.userInfo = res.data.data
        this.avatar = res.data.data.avatar
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    login(){
      this.$router.push({path:'/login'})
    },

    //修改头像
    profile(){
      this.axios.post(
        'api/user/profile', {
          'avatar': this.avatar
        }, {headers: {
          'token': this.token
        }
      }).then((res)=>{
        console.log(res)
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    //上传头像
    handleUploadSuccess(res, file) {
      if(res.code == 1){
        this.avatar = res.data.fullurl;
        this.profile();
      }
    },    

    beforeUpload(file) {
      console.log(file)
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (file.type.split('/')[0] != 'image') {
        this.$dialog('请上传图片文件'); return false;
      }
      if (!isLt2M) {
        this.$dialog('图片大小不能超过2MB'); return false;
      }
      return true;
    },

    //退出登录
    logout(){
      this.axios.post(
        'api/user/logout', {}, {headers: {
          'token': this.token
        }
      }).then((res)=>{
        this.$dialog(res.data.msg);
        if(res.data.code == 1){
          localStorage.removeItem('token');
          this.token = '';
        }else{
          return
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    changePass(){
      if(this.token){
        this.$router.push({path:'/change'});
      }else{
        this.$dialog('请先登录');
      }
    },

    authBook(){
      this.$router.push({path:'/auth'})
    },

    invite(){
      this.$router.push({path:'/invite'})
    },
    
  }
        
}
</script>

<style scoped>
  .member{
    padding: 10px;
  }
  .user_box{
    padding: 15px;
    background: #fff;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  .user {
    justify-content: flex-start;
  }
  .user .img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    overflow: hidden;
  }
  .user .img img{
    width: 55px;
    height: 55px;
    object-fit: cover;
  }
  .user .info{
    padding-left: 15px;
    text-align: left;
  }
  .user .info h3{
    font-size: 18px;
  }
  .user .info p{
    color: #999;
    padding-top: 5px;
    font-size: 15px;
  }
  .nav_list{
    padding: 5px 15px;
  }
  .nav_list ul li {
    overflow: hidden;
    padding: 15px 0;
    border-bottom: 1px solid #eee;
  }
  .nav_list ul li:last-child{
    border-bottom: none;
  }
  .nav_list ul li img {
    height: 20px;
  }
  .nav_list ul li p{
    font-size: 16px;
    color: #666;
  }
  .logout{
    margin-top: 50px;
  }
  .logout .el-button{
    width: 100%;
    height: 50px;
  }
  .logout >>> .el-button span{
    font-size: 19px;
  }
</style>