<template>
  <div class="content">
    <div class="header">
      <img src="../assets/img/left.png" @click="goback()"/> 
      <p>邀请海报</p>
    </div>

    <div class="invite">
      <img src="../assets/img/invitation.png" /> 
    </div>

    <div class="code" v-if="token"><p>我的专属邀请码：{{userInfo.invite_code}}</p></div>
  </div>
</template>

<script>
export default {
  name: 'InviteView',
  data() {
      return {
        token: '',
        userInfo: []
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {
    this.token = localStorage.getItem('token');
    if(localStorage.getItem('token')){
      this.getUserInfo();
    }
  },

  // 方法
  methods: {
    //获取用户信息
    getUserInfo(){
      this.axios.get(
        '/api/user/userInfo', {headers: {
          'token': localStorage.getItem('token')
        }
      }).then((res)=>{
        this.userInfo = res.data.data
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    goback(){
      this.$router.go(-1);
    },
  }
        
}
</script>

<style scoped>
  .invite{
    padding: 60px 15px 15px 15px;
    height: 100vh;
    margin-bottom: 80px;
  }
  .invite img{
    width: 100%;
    height: 100%;
  }
  .code{
    width: 100%;
    line-height: 50px;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 10px;
  }
  .code p{
    width: 100%;
    height: 100%;
    background: #0093dd;
    font-size: 20px;
    border-radius: 5px;
    color: #fff;
  }
</style>
