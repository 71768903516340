<template>
  <div class="content">
    <div class="header">
      <img src="../assets/img/left.png" @click="goback()"/> 
      <p>注册</p>
    </div>

    <div class="mt50"></div>

    <div class="login">
      <div class="slogan">尚岸快乐</div>
      <div class="login_form">
        <input class="input_text" type="number" v-model="mobile" placeholder="请输入手机号"/>
        <input class="input_text" type="password" v-model="password" placeholder="请输入密码"/>
        <input class="input_text" type="password" v-model="password2" placeholder="请再次输入密码"/>
        <input class="input_text" type="text" v-model="code" placeholder="请输入邀请码"/>
        <!-- <div class="code flex">
          <input class="input_text input_code" type="text" v-model="code"/>
          <input class="input_submit btn_code" type="submit" value="验证码"/>
        </div> -->
        
        <input class="input_submit" type="submit" value="立即注册" @click="register()"/>
        <div class="agree">
          <el-checkbox v-model="agree"><label @click="goAuth()">《个人信息授权书》</label></el-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'RegisterView',
  components: {
    Footer
  },
  data() {
      return {
        mobile: "",
        password: "",
        password2: "",
        code: "",
        agree: false,
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {
  },

  // 方法
  methods: {
    //注册
    register(){
      if(!this.mobile || !this.password || !this.password2){
        this.$dialog('注册信息不完善');
        return
      }
      if(!this.agree){
        this.$dialog('请先同意个人信息授权书');
        return
      }
      this.axios.post(
        'api/user/register', {
          mobile: this.mobile,
          password: this.password,
          password2: this.password2,
          code: this.code,
        }
      ).then((res)=>{
        var that = this
        this.$dialog(res.data.msg);
        if(res.data.code == 1){
          localStorage.setItem('token', res.data.data.token)
          setTimeout(function(){
            that.$router.push({path:'/member'})
          }, 1000)
        }else{
          return
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    goAuth(){
      this.$router.push({path:'/auth'})
    },

    // 返回上一页
    goback(){
      this.$router.go(-1);
    },
  }
        
}
</script>

<style scoped>
  .agree{
    text-align: left;
  }
</style>
