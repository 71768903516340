<template>
  <div class="content">
    <div class="header">
      <img src="../assets/img/left.png" @click="goback()"/> 
      <p>基本信息</p>
    </div>
    
    <div class="mt50"></div>

    <div class="basic">
      <div class="basic_top flex">
        <p>{{title}}</p>
      </div>
      <div class="basic_list">
        <div class="item">
          <div class="my_info">
            <div class="my_item flex">
              <p><span>*</span>姓名</p>
              <input type="text" v-model="name" placeholder="请输入姓名"/>
            </div> 
            <div class="my_item flex">
              <p><span>*</span>性别</p>
              <select class="repay_date" v-model="gender">
                <option value="0">请选择</option>
                <option value="1">男</option>
                <option value="2">女</option>
              </select>
            </div> 
            <div class="my_item flex">
              <p><span>*</span>身份证号</p>
              <input type="text" v-model="idcard" placeholder="请输入身份证号"/>
            </div> 
            <div class="my_item flex">
              <p><span>*</span>年龄</p>
              <input type="number" v-model="age" placeholder="请输入年龄"/>
            </div> 
            <div class="my_item flex">
              <p><span>*</span>学历</p>
              <select class="repay_date" v-model="education">
                <option value="0">请选择</option>
                <option value="1">小学</option>
                <option value="2">初中</option>
                <option value="3">高中</option>
                <option value="4">大专</option>
                <option value="5">本科及以上</option>
              </select>
            </div> 
            <div class="my_item flex">
              <p><span>*</span>属相</p>
              <select class="repay_date" v-model="animal">
                <option value="0">请选择</option>
                <option value="鼠">鼠</option>
                <option value="牛">牛</option>
                <option value="虎">虎</option>
                <option value="兔">兔</option>
                <option value="龙">龙</option>
                <option value="蛇">蛇</option>
                <option value="马">马</option>
                <option value="羊">羊</option>
                <option value="猴">猴</option>
                <option value="鸡">鸡</option>
                <option value="狗">狗</option>
                <option value="猪">猪</option>
              </select>
            </div> 
            <div class="my_item flex">
              <p><span>*</span>婚姻状况</p>
              <select class="repay_date" v-model="marry">
                <option value="0">请选择</option>
                <option value="1">未婚</option>
                <option value="2">已婚</option>
              </select>
            </div> 
            <div class="my_item flex">
              <p><span>*</span>邮箱号</p>
              <input type="text" v-model="email" placeholder="请输入邮箱号"/>
            </div> 
            <div class="my_item flex">
              <p><span>*</span>户籍地址</p>
              <input type="text" v-model="address" placeholder="请输入户籍地址" />
            </div> 
            <div class="my_item flex">
              <p><span>*</span>银行预留地址</p>
              <input type="text" v-model="bank_addr" placeholder="请输入银行预留地址" />
            </div> 
            <div class="my_item flex">
              <p><span>*</span>预留单位名称</p>
              <input type="text" v-model="company" placeholder="请输入预留单位" />
            </div> 
            <div class="my_item flex">
              <p><span>*</span>预留单位地址</p>
              <input type="text" v-model="company_addr" placeholder="请输入预留单位地址" />
            </div> 
            <div class="my_item flex">
              <p><span>*</span>银行预留手机号</p>
              <input type="number" v-model="mobile" placeholder="请输入银行预留手机号" />
            </div> 
            <div class="my_item flex">
              <p><span>*</span>新办联通号</p>
              <input type="number" v-model="uni_mobile" placeholder="请输入新办联通号" />
            </div> 
          </div>

          <div class="contacts">
            <div class="contact_item contact_head flex">
              <p>紧急联系人</p><p>关系</p><p>手机号</p>  
            </div>
            <div class="contact_item flex" v-for="(item, index) in contacts">
              <input type="text" v-model="item.name" placeholder="姓名"/>
              <input type="text" v-model="item.relation" placeholder="关系"/>
              <input type="text" v-model="item.mobile" placeholder="手机号"/>
            </div>
            <div class="contact_item flex"><p></p><el-button @click="duplicate()">添加</el-button></div> 
          </div> 

          <div class="remark" style="display: none">
            <p>备注：紧急联系人的姓名和关系请如实填写。<br/>
              手机号码请勿填写空号或者无人接听的号码。<br/>
              五万以上的欠款务必写真实使用过的号码。</p>
          </div> 
        </div>
      </div>

      <div class="operate" @click="saveBasic">
        <el-button type="primary">保存</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'BasicInfoView',
  components: {
    Footer
  },
  data() {
      return {
        id: 0,
        title: '信息录入',
        name: '',
        idcard: '',
        gender: 0,
        age: '',
        education: 0,
        animal: 0,
        marry: 0,
        email: '',
        address: '',
        bank_addr: '',
        company: '',
        company_addr: '',
        mobile: '',
        uni_mobile: '',
        contacts: [
          {"name": "", "relation": "", "mobile": ""},
        ],
      };
    },

  created() {
    
  },

  //模板渲染后调用
  mounted() {
    this.id = this.$route.query.id;
    if(this.id){
      this.title = '信息编辑';
      this.getBasicInfo();
    }
  },

  // 方法
  methods: {
    //获取基本信息
    getBasicInfo(){
      this.axios.get(
        '/api/userinfo/getBasicInfo?id='+this.id, {headers: {
          'token': localStorage.getItem("token")
        }
      }).then((res)=>{
        this.name = res.data.data.name;
        this.gender = res.data.data.gender;
        this.idcard = res.data.data.idcard;
        this.age = res.data.data.age;
        this.education = res.data.data.education;
        this.animal = res.data.data.animal;
        this.marry = res.data.data.marry;
        this.email = res.data.data.email;
        this.address = res.data.data.address;
        this.bank_addr = res.data.data.bank_addr;
        this.company = res.data.data.company;
        this.company_addr = res.data.data.company_addr;
        this.mobile = res.data.data.mobile;
        this.uni_mobile = res.data.data.uni_mobile;
        if(res.data.data.contacts){
          this.contacts = res.data.data.contacts;
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    //保存基本信息
    saveBasic(){  
      var that = this; 
      if(!this.name || !this.gender || !this.idcard || !this.age || !this.education || !this.animal || !this.marry || !this.email 
      || !this.address || !this.bank_addr || !this.company || !this.company_addr || !this.mobile || !this.uni_mobile){
        this.$dialog('信息不完整，不能提交');
        return false;
      }

      if(this.mobile == this.uni_mobile){
        this.$dialog('现用手机号和联通手机号不能相同');
        return false;
      }

      this.axios.post(
        'api/userinfo/saveUserInfo', {
          id: this.id,
          name: this.name,
          gender: this.gender,
          idcard: this.idcard,
          age: this.age,
          education: this.education,
          animal: this.animal,
          marry: this.marry,
          email: this.email,
          address: this.address,
          bank_addr: this.bank_addr,
          company: this.company,
          company_addr: this.company_addr,
          mobile: this.mobile,
          uni_mobile: this.uni_mobile,
          contacts: JSON.stringify(this.contacts),
        }, {headers: {
          'token': localStorage.getItem('token')
        }
      }).then((res)=>{
        if(res.data.code == 1){
          this.$dialog('提交成功');
          setTimeout(() => {
            that.$router.push({path:'/basic'});
          }, 1000);
        }else{
          this.$dialog(res.data.msg);
          return false;
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    goback(){
      this.$router.push({path:'/basic'})
    },

    //复制
    duplicate () {      
      if( !this.contacts[0]['name'] || !this.contacts[0]['relation'] || !this.contacts[0]['mobile'] ){
        this.$dialog('请先填写完整第一个联系人');
        return false;
      }

      const length = this.contacts.length;
      if(length == 2){
        if(this.contacts[0]['name'] == this.contacts[1]['name'] || this.contacts[0]['mobile'] == this.contacts[1]['mobile']){
          this.$dialog('两个联系人姓名或手机不能相同');
          return false;
        }

        if(!this.contacts[1]['name'] || !this.contacts[1]['relation'] || !this.contacts[1]['mobile']){
          this.$dialog('请先填写完整第二个联系人');
          return false;
        }
        
      }

      if(length >= 3){
        return false;
      }
      // 复制最后一个元素并添加到数组中
      const lastItem = {"name": "", "relation": "", "mobile": ""};

      this.contacts.push(lastItem);
    },

  },
        
}
</script>

<style scoped>
</style>
