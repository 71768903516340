<template>
  <div class="content">
    <div class="header"><p>首页</p></div>

    <div class="mt50"></div>

    <div class="banner">
      <template>
        <el-carousel indicator-position="outside" :interval="3500">
          <el-carousel-item v-for="(item, index) in banner" :key="index">
            <img :src="item.url" />
          </el-carousel-item>
        </el-carousel>
      </template>
    </div>

    <div class="intro flex">
      <div class="item flex"><img src="../assets/img/icon_lawer.png" /><span>专业律师</span></div>
      <div class="item flex"><img src="../assets/img/icon_role.png" /><span>合规合法</span></div>
      <div class="item flex"><img src="../assets/img/icon_service.png" /><span>全程服务</span></div>
      <div class="item flex"><img src="../assets/img/icon_process.png" /><span>流程透明</span></div>
    </div>

    <div class="nav_box">
      <ul>
        <li class="flex" @click="basic()">
          <div class="nav_title"><p>基本信息</p><span>录入个人基本信息</span></div>
          <div class="icon flex"><img src="../assets/img/icon_nav1.png" /></div>
        </li>
        <li class="flex" @click="cards()">
          <div class="nav_title"><p>我的卡片</p><span>录入卡片信息</span></div>
          <div class="icon flex"><img src="../assets/img/icon_nav2.png" /></div>
        </li>
        <li class="flex" @click="order(1)">
          <div class="nav_title"><p>债务托管</p><span>债务托管服务</span></div>
          <div class="icon flex"><img src="../assets/img/icon_nav3.png" /></div>
        </li>
        <li class="flex" @click="order(2)">
          <div class="nav_title"><p>代协商债务</p><span>债务协商服务</span></div>
          <div class="icon flex"><img src="../assets/img/icon_nav4.png" /></div>
        </li>
        <li class="flex" @click="order(3)">
          <div class="nav_title"><p>个性化分期</p><span>债务分期服务</span></div>
          <div class="icon flex"><img src="../assets/img/icon_nav5.png" /></div>
        </li>
        <li class="flex" @click="order(4)">
          <div class="nav_title"><p>网贷停催</p><span>提供网贷停催服务</span></div>
          <div class="icon flex"><img src="../assets/img/icon_nav6.png" /></div>
        </li>
      </ul>
    </div>

    <Footer activeIndex="0"/>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'IndexView',
  components: {
    Footer
  },
  data() {
      return {
        banner: [
          {id: 1, url: require("@/assets/img/banner1.png")},
          {id: 2, url: require("@/assets/img/banner2.png")},          
        ],
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {
  },

  // 方法
  methods: {
    basic(){
      if(this.checkLogin()){
        this.$router.push({path: "/basic"});
      };
    },

    cards(){
      if(this.checkLogin()){
        this.$router.push({path: "/cards"});
      };
    },

    order(type){
      if(this.checkLogin()){
        this.$router.push({path: "/order", query: {type: type}});
      };
    },

    advice(){
      this.$dialog('待开放');
    },

    //校验登录
    checkLogin(){
      const token = localStorage.getItem("token");
      if(!token){
        this.$dialog('请先登录');
        return false;
      }
      return true;
    }
  },
        
}
</script>

<style scoped>
  .banner{
    width: 100%;
    height: 180px;
    overflow: hidden;
    padding: 10px 2.5% 0;
    background: #fff;
  }
  .el-carousel{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
  }
  .banner >>> .el-carousel .el-carousel__container{
    width: 100%;
    height: 100%;
  }
  .banner >>> .el-carousel__indicators--outside{
    display: none;
  }
  .banner >>> .el-carousel-item{
    border-radius: 5px;
    overflow: hidden;
  }
  .banner >>> .el-carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .banner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .intro {
    padding: 10px 20px;
    background: #fff;
  }
  .intro .item{
    justify-content: center;
  }
  .intro .item img{
    height: 18px;
  }
  .intro .item span{
    padding-left: 3px;
    font-size: 13px;
    color: #999;
  }

  .nav_box{
    padding: 0 15px;
    margin-top: 15px;
  }
  .nav_box ul{
    overflow: hidden;
  }
  .nav_box ul li{
    width: 48%;
    float: left;
    margin-right: 4%;
    padding: 15px 10px;
    background: #0093dd;
    border-radius: 5px;
    margin-bottom: 15px;
    color: #fff;
  }
  .nav_box ul li:nth-child(2n){
    margin-right: 0;
  }
  .nav_box ul li .icon{
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: #fff;
    justify-content: center;
  }
  .nav_box ul li .icon img{
    height: 45px;
  }
  .nav_box ul li .nav_title{
    text-align: left;
  }
  .nav_box ul li p{
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    padding-bottom: 3px;
  }
  .nav_box ul li span{
    color: #eee;
    font-size: 12px;
  }
</style>
