<template>
  <div class="content">
    <div class="header">
      <img src="../assets/img/left.png" @click="goback()"/> 
      <p>个人信息授权书</p>
    </div>

    <div class="auth">
      <h2>个人信息使用授权书</h2>
      <p>本人对本授权书所有条款的含义及相应的法律后果已全部知晓并充分理解，并且明白。</p>
      <p>1、本人明确对公司作如下个人信息查询、使用授权；信息（包含本人的姓名、身份证号、银行卡号、扣款数额、邮箱地址、信用卡信息、逾期信息、教育信息、联系人信息、工作信息、财产信息、资信状况、就业情况、收入情况、婚姻情况、地址信息等）及本人还 款信息。</p>
      <p>2、本人同意并授权个人身份证复印和银行卡复印件，未经本人向公司正式提出撤销授权，该授权持续有效。</p>
      <p>3、公司获取的个人所有信息包含手机卡在内, 除了以上提及的收集、使用目的，不会用于其它用途，否则，将承担相应的法律责任。依据相关法规,如无正当的理由继续保存个人信息,或个人信息收集使用目的已经达成,本公司会立即删除相关个人信息，手机卡将会原路退还客户。</p>
      <p>4、本授权经接受后即时生效，且效力具有独立性，不因相关业务合同或条款无效。</p>
      <p>5、本人已知悉本授权书全部内容(特别是加粗字体内容) 的含义及因此产的的法律效力，自愿作出以上授权。本授权是本人真实意思表示，本人同意承担由此带来的一切法律后果。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthView',
  data() {
      return {
        
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {
  },

  // 方法
  methods: {
    goback(){
      this.$router.go(-1);
    },
  }
        
}
</script>

<style scoped>
  .auth{
    padding: 70px 20px 20px 20px;
    background: #fff;
    min-height: 100vh;
  }
  .auth h2{
    font-size: 20px;
    padding-bottom: 10px;
  }
  .auth p{
    text-align: left;
    font-size: 16px;
    line-height: 28px;
    margin-top: 5px;
    text-indent: 2rem;
  }
</style>
