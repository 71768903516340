<template>
  <div class="content">
    <div class="header">
      <img src="../assets/img/left.png" @click="goback()"/> 
      <p>修改密码</p>
    </div>

    <div class="mt50"></div>

    <div class="login">
      <div class="slogan">尚岸快乐</div>
      <div class="login_form">
        <!-- <input class="input_text" type="text" name="username" placeholder="请输入手机号"/> -->
        <input class="input_text" type="password" v-model="password" placeholder="请输入旧密码"/>
        <input class="input_text" type="password" v-model="password2" placeholder="请输入新密码"/>
        <input class="input_text" type="password" v-model="password3" placeholder="请再次输入新密码"/>
        <!-- <div class="code flex">
          <input class="input_text input_code" type="text" name="code" v-model="code" readonly/>
          <input class="input_submit btn_code" type="submit" value="验证码"/>
        </div> -->
        <input class="input_submit" @click="changePass()" type="submit" value="提交"/>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'ChangeView',
  components: {
    Footer
  },
  data() {
      return {
        code: "123456",
        password: '',
        password2: '',
        password3: '',
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {
  },

  // 方法
  methods: {
    //修改密码
    changePass(){
      console.log(this.password, this.password2, this.password3)
      if(!this.password || !this.password2 || !this.password3){
        this.$dialog('新密码或旧密码为空，无法提交');
        return false;
      }

      this.axios.post(
        'api/user/changePass', {
          password: this.password,
          password2: this.password2,
          password3: this.password3,
          code: this.code,
        }, {headers: {
          'token': localStorage.getItem('token')
        }
      }).then((res)=>{
        if(res.data.code == 1){
          localStorage.setItem('token', '');
          this.$dialog('密码修改成功，请重新登录');
          setTimeout(() => {
            this.$router.push({path:'/login'})
          }, 1000);
        }else{
          this.$dialog(res.data.msg);
          return false
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    goback(){
      this.$router.go(-1);
    },

  }
        
}
</script>

<style scoped>
  
</style>
