<template>
  <div class="content">
    <div class="header">
      <img src="../assets/img/left.png" @click="goback()"/> 
      <p>我的卡片</p>
    </div>

    <div class="mt50"></div>

    <div class="basic">
      <div class="basic_top flex">
        <p>{{title}}</p>
      </div>
      <div class="basic_list">
        <div class="item">
          <div class="my_info">
            <div class="my_item flex">
              <p><span>*</span>持卡人</p>
              <select v-model="userinfo_id">
                <option v-if="!userinfo_id" value="0" selected>请选择</option>
                <option v-for="item in basicList" :value="item.id">{{item.name}}</option>
              </select>
            </div> 
            <div class="my_item flex">
              <p><span>*</span>开户行</p>
              <input type="text" v-model="bank_name" placeholder="请输入开户行"/>
            </div> 
            <div class="my_item flex">
              <p><span>*</span>卡号</p>
              <input type="number" v-model="card_number" placeholder="请输入卡号"/>
            </div> 
            <div class="my_item flex">
              <p><span>*</span>欠款金额</p>
              <input type="number" v-model="amount" placeholder="请输入欠款金额"/>
            </div> 
            <div class="my_item flex">
              <p><span>*</span>激活城市</p>
              <input type="text" v-model="activation" placeholder="请输入激活城市"/>
            </div> 
            <div class="my_item flex">
              <p><span>*</span>预留手机号</p>
              <input type="number" v-model="mobile" placeholder="请输入手机号"/>
            </div> 
            <div class="my_item repay_date flex">
              <p><span>*</span>还款日</p>
              <select v-model="repay_date" class="repay_date">
                <option value="0" v-if="!repay_date" selected>请选择</option>
                <option v-for="item in dates" :value="item">每月{{item}}日</option>
              </select>
            </div>
            <div class="my_item flex">
              <p><span>*</span>逾期时间</p>
              <input type="date" v-model="exceed_date" />
            </div> 
            <div class="my_item flex">
              <p><span>*</span>查询密码</p>
              <input type="number" v-model="query_pass" placeholder="请输入查询密码"/>
            </div> 
            <div class="my_item flex">
              <p><span>*</span>交易密码</p>
              <input type="number" v-model="trade_pass" placeholder="请输入交易密码"/>
            </div>             
          </div>
          <div class="contacts">
            <div class="contact_item contact_head flex">
              <p>预留联系人</p><p>关系</p><p>手机号</p>  
            </div>
            <div class="contact_item flex" v-for="(item, index) in contacts">
              <input type="text" v-model="item.name" placeholder="姓名"/>
              <input type="text" v-model="item.relation" placeholder="关系"/>
              <input type="text" v-model="item.mobile" placeholder="手机号"/>
            </div>
            <!-- <div class="contact_item add flex"><p></p><el-button @click="duplicate()">添加</el-button></div>  -->
          </div> 
        </div>
      </div>
      <div class="operate" @click="saveCard">
        <el-button type="primary">保存</el-button>
      </div>
    </div>

    
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'CardsView',
  components: {
    Footer
  },
  data() {
      return {
        id: 0,
        title: '卡片录入',
        bankList: [],
        basicList: [],
        dates: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
        exceed_arr: ["3个月内", "6个月内", "12个月内", "24个月内", "36个月内", "36个月以上"],
        userinfo_id: 0,
        bank_name: '',
        card_number: '',
        amount: '',
        activation: '',
        mobile: '',
        query_pass: '',
        trade_pass: '',
        repay_date: 0,
        exceed_date: '',
        contacts: [
          {"name": "", "relation": "", "mobile": ""},
          {"name": "", "relation": "", "mobile": ""},
        ],
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {
    this.getBankList();
    this.getBasicInfoList();
    this.id = this.$route.query.id;
    if(this.id){
      this.title = '卡片编辑';
      this.getCardInfo();
    }
  },

  // 方法
  methods: {
    //获取卡片详情
    getCardInfo(){
      this.axios.get(
        '/api/mycard/cardInfo?id='+this.id, {headers: {
          'token': localStorage.getItem("token")
        }
      }).then((res)=>{
        this.userinfo_id = res.data.data.userinfo_id;
        this.bank_name = res.data.data.bank_name;
        this.card_number = res.data.data.card_number;
        this.amount = res.data.data.amount;
        this.activation = res.data.data.activation;
        this.mobile = res.data.data.mobile;
        this.query_pass = res.data.data.query_pass;
        this.trade_pass = res.data.data.trade_pass;
        this.repay_date = res.data.data.repay_date;
        this.exceed_date = res.data.data.exceed_date;
        this.contacts = res.data.data.contacts;
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    //获取基本信息列表
    getBasicInfoList(){
      this.axios.get(
        '/api/user/getBasicInfoList', {headers: {
          'token': localStorage.getItem("token")
        }
      }).then((res)=>{
        this.basicList = res.data.data;        
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    //获取银行列表
    getBankList(){
      this.axios.get(
        '/api/mycard/getBankList', {headers: {
          'token': localStorage.getItem("token")
        }
      }).then((res)=>{
        this.bankList = res.data.data
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    //保存银行卡信息
    saveCard(){
      if( !this.contacts[0]['name'] || !this.contacts[0]['relation'] || !this.contacts[0]['mobile'] ||
      !this.contacts[1]['name'] || !this.contacts[1]['relation'] || !this.contacts[1]['mobile'] ){
        this.$dialog('请完整填写联系人信息');
        return false;
      }

      if(this.contacts[0]['name'] == this.contacts[1]['name'] || this.contacts[0]['mobile'] == this.contacts[1]['mobile']){
        this.$dialog('两个联系人姓名或手机不能相同');
        return false;
      }

      var that = this;
      this.axios.post(
        'api/mycard/saveCard', {
          id: this.id,
          userinfo_id: this.userinfo_id,
          bank_name: this.bank_name,
          card_number: this.card_number,
          amount: this.amount,
          activation: this.activation,
          mobile: this.mobile,
          query_pass: this.query_pass,
          trade_pass: this.trade_pass,
          repay_date: this.repay_date,
          exceed_date: this.exceed_date,
          contacts: JSON.stringify(this.contacts),
        }, {headers: {
          'token': localStorage.getItem('token')
        }
      }).then((res)=>{
        if(res.data.code == 1){
          this.$dialog('提交成功');
          setTimeout(() => {
            that.$router.push({path:'/cards'});
          }, 1000);
        }else{
          this.$dialog(res.data.msg);
          return false;
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    //复制
    // duplicate () {      
    //   if( !this.contacts[0]['name'] || !this.contacts[0]['relation'] || !this.contacts[0]['mobile'] ){
    //     this.$dialog('请先填写完整第一个联系人');
    //     return false;
    //   }

    //   const length = this.contacts.length;
    //   if(length == 2){
    //     if(this.contacts[0]['name'] == this.contacts[1]['name'] || this.contacts[0]['mobile'] == this.contacts[1]['mobile']){
    //       this.$dialog('两个联系人姓名或手机不能相同');
    //       return false;
    //     }

    //     if(!this.contacts[1]['name'] || !this.contacts[1]['relation'] || !this.contacts[1]['mobile']){
    //       this.$dialog('请先填写完整第二个联系人');
    //       return false;
    //     }
        
    //   }

    //   if(length >= 3){
    //     return false;
    //   }
    //   // 复制最后一个元素并添加到数组中
    //   const lastItem = {"name": "", "relation": "", "mobile": ""};

    //   this.contacts.push(lastItem);
    // },

    goback(){
      this.$router.push({path:'/cards'})
    },
  },
        
}
</script>

<style scoped>
  
</style>
