<template>
  <div class="content">
    <div class="header">
      <img src="../assets/img/left.png" @click="goback()"/> 
      <p>{{title}}</p>
    </div>

    <div class="mt50"></div>

    <div class="basic">
      <div class="basic_top flex"><p>创建订单</p></div>
      <div class="basic_list">
        <div class="item">
          <div class="my_info">
            <div class="my_item flex">
              <p><span>*</span>姓名</p>
              <select v-model="userinfo_id" @change="selectUser()">
                <option v-if="!userinfo_id" value="0">请选择</option>
                <option v-for="(item, index) in basicList" :value="item.id">{{item.name}}</option>
              </select>
            </div> 
            <div class="my_item flex" v-if="type == 1">
              <p><span>*</span>托管周期</p>
              <select v-model="cycle">
                <option v-for="(item, index) in dateArr" :value="item">{{item}}</option>
              </select>
            </div> 
            <div class="my_item flex" v-if="type == 4">
              <p><span>*</span>借款平台</p>
              <input type="text" v-model="platform" placeholder="请输入借款平台"/>
            </div> 
            <div class="my_item flex" v-for="(item, index) in items" v-if="type < 4">
              <p><span>*</span>逾期卡片</p>
              <select v-model="cardIds[index]">
                <option v-for="(item, idx) in cardList" :value="item.id">
                  {{item.card_number}}
                </option>
              </select>
            </div> 
            <div class="my_item flex" v-if="type < 4">
              <p></p>
              <el-button @click="duplicate()" :disabled="items.length >= cardNum">添加卡片</el-button>
            </div> 
          </div>
        </div>

        <div class="upload_file" v-if="type < 4">
          <p><span>*</span>附件1（请上传清晰的身份证正反照及信用卡正反照）</p>
          <div class="item item-images">
            <el-upload
              :action="uploadUrl"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-success="uplaodImgList"
              :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </div>
        </div>

        <div class="upload_file" v-if="type < 4">
          <p>附件2（音频类）</p>
          <div class="item">
            <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="handleAudioSuccess"
                :before-upload="beforeAudioUpload">
                <img v-if="audioUrl" src="../assets/img/audio.png" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
          </div>
        </div>
      </div>

      <div class="operate" @click="saveOrder">
        <el-button type="primary">提交</el-button>
      </div>
    </div>
    
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'OrderInfoView',
  components: {
    Footer
  },
  data() {
      return {
        uploadUrl: this.axios.defaults.baseURL + '/api/common/upload', //图片上传地址
        title: '债务托管',
        type: 1,
        basicList: [],
        userinfo_id: 0,
        titleArr: ['债务托管', '代协商债务', '个性化分期', '网贷停催'],
        dateArr: ['3个月', '4个月', '5个月', '6个月', '12个月'],
        cycle: '3个月', //托管周期
        platform: '',
        dialogImageUrl: '',
        dialogVisible: false,
        audioUrl: '',
        imgList: [],
        items: ['item1'],
        cardList: [{id: 0, card_number: '请选择'}],
        cardId: '',
        cardIds: [0],
        cardNum: 0,
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {
    this.type = this.$route.query.type;
    this.title = this.titleArr[this.type - 1];
    this.getBasicList();
    if(this.type > 1){
      this.cycle = '';
    }
  },

  // 方法
  methods: {
    //获取基本信息列表
    getBasicList(){
      this.axios.get(
        '/api/user/getBasicInfoList', {headers: {
          'token': localStorage.getItem('token')
        }
      }).then((res)=>{
        this.basicList = res.data.data
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    //选择持卡人
    selectUser(){
      this.items = ['item1'];
      //查询改用户下的银行卡
      this.axios.get(
        '/api/mycard/getCardListByUser?userinfo_id='+this.userinfo_id, {headers: {
          'token': localStorage.getItem('token')
        }
      }).then((res)=>{
        if(res.data.data.list.length > 0){
          res.data.data.list.forEach(item => {
            this.cardList.push(item);
          });
          this.cardNum = res.data.data.card_num;
        }else{
          this.$dialog('该用户未添加卡片');
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    //提交订单
    saveOrder(){
      if(!this.userinfo_id){
        this.$dialog('请选择借款人'); return false;
      }

      if(this.type < 4 && !this.cardIds){
        this.$dialog('请选择欠款卡片'); return false;
      }

      if(this.type == 4 && !this.platform){
        this.$dialog('请填写借款平台'); return false;
      }

      if(this.type < 4 && this.imgList.length < 4){
        this.$dialog('请至少上传4张图片'); return false;
      }

      this.axios.post(
        'api/orders/createOrder', {
          typelist: this.type,
          userinfo_id: this.userinfo_id,
          cycle: this.cycle,
          platform: this.platform,
          card_ids: this.type == 4 ? '' : this.cardIds.join(","),
          images: this.imgList.join(","),
          audio_file: this.audioUrl,
        }, {headers: {
          'token': localStorage.getItem("token")
        }
      }).then((res)=>{
        if(res.data.code == 1){
          this.$dialog(res.data.msg); 
          this.$router.push({path:'/order', query: {type: this.type}})
        }else{
          this.$dialog(res.data.msg); 
          return false;
        }
      }).catch((res)=>{
        this.$checkError(res); 
      })
    },

    //复制
    duplicate () {
      //当前持卡人卡片数量
      const count = this.cardList.length;
      //当前已添加的逾期卡片dom数量
      const length = this.items.length;
      
      //如果dom数量大于等于卡片数量则不可添加
      if(length >= count){
        this.$dialog('没有更多可选择的卡片'); 
        return false;
      }
      // 复制最后一个元素并添加到数组中
      const lastItem = this.items[length - 1];
      this.items.push(lastItem);
    },

    //图片
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //多图上传成功
    uplaodImgList(res, file, fileList){
      var files = res.data.fullurl;
      this.imgList.push(files);
      this.fileList = fileList;
    },

    //音频
    handleAudioSuccess(res, file) {
      this.audioUrl = res.data.fullurl;
    },
    beforeAudioUpload(file) {
      const isAudio = file.type === 'audio/mpeg';
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isAudio) {
        //this.$dialog('请上传音频文件'); return false;
      }
      if (!isLt20M) {
        this.$dialog('音频文件大小不能超过20MB'); return false;
      }
      return isLt20M;
    },

    goback(){
      this.$router.push({path:'/order'})
    },

    
  },
        
}
</script>

<style scoped>
  .avatar-uploader{
    display: flex;
    justify-content: flex-start;
  }
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 75px;
    height: 75px;
    line-height: 75px;
    text-align: center;
    border: 1px dashed #c0ccda;
    border-radius: 5px;
  }
  .avatar {
    width: 75px;
    height: 75px;
    display: block;
    border: 1px solid #ccc;
  }
  .basic_list .item >>> .el-upload--picture-card{
    width: 75px;
    height: 75px;
    line-height: 88px;
  }
  .basic_list .item >>> .el-upload-list__item{
    width: 75px;
    height: 75px;
    line-height: 75px;
  }
  .basic_list .item >>> .el-upload-list__item.is-success .el-upload-list__item-status-label{
    display: none;
  }
  .item-images > div {
    text-align: left;
  }
  .basic{
    padding: 10px 10px 20px 10px;
  }
  .basic .basic_top p{
    font-size: 16px;
  }
  .basic .basic_top span{
    color: #0093dd;
  }
  .basic .basic_list .item{
    margin-top: 10px;
    padding: 15px 10px;
    border-radius: 10px;
    background: #fff;
  }
  .basic .basic_list .item .my_info{
    padding: 0 0 10px 0;
  }
  .basic .basic_list .item .my_info .my_item{
    margin-bottom: 8px;
  }
  .basic .basic_list .item .my_info .my_item p{
    width: 30%;
    text-align: left;
  }
  .basic .basic_list .item .my_info .my_item input{
    width: 70%;
    height: 33px;
    border: none;
    background: #eee;
    border-radius: 3px;
    padding-left: 10px;
  }
  .basic .basic_list .item .my_info .my_item select{
    width: 70%;
    height: 33px;
    border: none;
    background: #eee;
    border-radius: 3px;
    padding-left: 10px;
  }
  .basic .basic_list .item .my_info .my_item .el-button{
    margin-top: 10px;
    padding: 8px 12px;
  }
  .basic_list .upload_file > p{
    margin-top: 15px;
    text-align: left;
    font-size: 15px;
  }
  .operate{
    margin-top: 20px;
  }
  .operate .el-button{
    width: 100%;
    height: 100%;
  }
  .remark {
    margin-top: 15px;
    text-align: left;
  }
  .remark p{
    line-height: 22px;
  }
</style>
