<template>
  <div class="content">
    <div class="header"><p>客服</p></div>

    <div class="mt50"></div>

    <div class="custom_list">

      <div class="item flex" v-for="(item, index) in customList">
        <div class="left flex">
          <img src="../assets/img/avatar.png" />
          <div class="custom">
            <p>{{item.nickname}}</p>
            <span v-if="item.msg_count > 0" style="color:#f00">有新消息（{{item.last_time}}）</span>
            <span v-else>无新消息</span>
          </div>
        </div>
        <div class="right" @click="chatDetail(item.id)"><el-button type="primary">沟通</el-button></div>
      </div>

    </div>

    <div class="response">
      <h2>职责说明</h2>
      <p>市场部客服：初次审核，综合客诉问题。</p>
      <p>审核部客服：二次审核，违规取证，停催处理及通知等。</p>
      <p>客服工作时间：周一至周六 / 上午9:00-11:30 / 下午13:30-18:00。</p>
    </div>

    <Footer activeIndex="1"/>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'KefuView',
  components: {
    Footer
  },
  data() {
      return {
        customList: [],
        timer: ''
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {    
    this.getCustomList();
    this.refreshCustomList();
  },

  // 方法
  methods: {
    //客服列表
    getCustomList(){
      this.axios.get(
        '/api/Message/getCustomList', {headers: {
          'token': localStorage.getItem('token')
        }
      }).then((res)=>{
        this.customList = res.data.data;
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    //刷新客服列表
    refreshCustomList(){
      var that = this;
      this.timer = setInterval(function(){
        that.getCustomList();
      }, 5000)
    },

    //聊天详情
    chatDetail(adminId){
      const token = localStorage.getItem("token");
      if(!token){
        this.$dialog('请先登录');
        return false;
      }
      this.$router.push({path: '/chat', query: {adminId: adminId}});

      // 销毁当前页面的组件
      this.$destroy();
    }
  },

  beforeDestroy() {
    // 在销毁前执行一些逻辑:清楚定时器
    clearInterval(this.timer);
  },
        
}
</script>

<style scoped>
  .custom_list{
    background: #fff;
  }
  .custom_list .item{
    padding: 15px 20px;
    border-bottom: 1px solid #eee;
  }
  .custom_list .item:last-child{
    border-bottom: none;
  }
  .custom_list .item .left img{
    width: 50px;
    height: 50px;
    border-radius: 50px;
  }
  .custom_list .item .left .custom{
    text-align: left;
    padding-left: 10px;
  }
  .custom_list .item .left .custom p{
    font-weight: bold;
    padding-bottom: 3px;
    font-size: 16px;
  }
  .custom_list .item .left .custom span{
    color: #666;
  }
  .custom_list .item .right >>> .el-button{
    padding: 8px 18px;
    font-size: 16px;
  }
  .custom_list .item .right >>> .el-button span{
    font-size: 16px !important;
  }
  .response{
    padding: 20px;
    text-align: left;
  }
  .response h2{
    font-size: 18px;
    padding-bottom: 10px;
  }
  .response p{
    line-height: 30px;
    color: #666;
    font-size: 14px;
  }
</style>